<template>
  <div class="p-4 text-left bg-bg-gray">
    <router-view name="avisos" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
